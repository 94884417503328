// Core react and plugins
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

// Utilities
import Fetching from './../../../utilities/fetching';
import Encryption from '../../../utilities/encryption';

// State management
import { useCookies } from 'react-cookie';
import useFlash from './../../../store/flash';

import ApplicationList from '../../../components/admin/applications';

const ApplicationListContainer = () => {

    // State management

    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [llEnabled, setLlEnabled] = useState(true); // eslint-disable-line
    const [error, setError] = useState(false);
    const [applications, setApplications] = useState([]);
    const [flashState, flashActions] = useFlash();
    const [authCookie] = useCookies([process.env.REACT_APP_AUTH_COOKIE]);
    const [nextPage, setNextPage] = useState(0); // eslint-disable-line
    const [userPermissions,setUserPermissions] = useState({})
    const [archiveDate, setArchiveDate] = useState(new Date());
    const [filters,setFilters] = useState({
        searchInput: '',
        incInProgress: false,
        incArchived: false
    });

    let navigate = useNavigate();

    let loginUrl = "/";
    let redirectUrl = '/admin/dashboard'

    // Check that we have a partially complete auth status (based upon the cookie)
    let loadApplications = (isSearch) => {

        if (loading) return;
        if (!llEnabled && nextPage > 1 && typeof isSearch == "undefined") return;

        setLoading(true);

        let authInfo = Encryption.extractHeaders(authCookie);

        let filterData = { ...filters }

        if (isSearch) {
            filterData.page = 0;
            setApplications([]);
            setLlEnabled(true);
        } else {
            filterData.page = nextPage;
        }

        // Check the server side to see what the status of this auth is...
        fetch(process.env.REACT_APP_API_BASE + '/admin-applications/list', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(filterData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {
            // Set the state for the users

                setLoading(false);

                if (data.rslt === "success" && typeof data.detail !== "undefined") {

                    if (data.detail === null) {
                        if (filterData.page === 0) {
                            setApplications([]);
                        }
                        setLlEnabled(false);
                    } else {

                        if (data.detail.length === 0) {
                            setLlEnabled(false);
                        } else if (filterData.page === 0) {
                            setApplications(data.detail);
                        } else {
                            // This is an update via lazy load 
                            var newApplications = [...applications];
                            for (var i in data.detail) {
                                newApplications.push(data.detail[i]);
                            }
                            setApplications(newApplications);
                        }

                        setNextPage(filterData.page + 1);
                        if (data.detail.length < 25) {
                            setLlEnabled(false);
                            return;
                        }
                        setLlEnabled(true);

                    }
                }

            // setError({ msg: "Sorry but there was an error loading the users.", style: "red" });
            setLoading(false);

        })
        .catch(function (error) {
            if (error === 'auth') {
                flashActions.set({ msg: "Sorry but we could not find the applications list that you are looking for. Please sign in and try again.", style: "red" });
            }
            navigate(loginUrl);
        });
    }

    const updateFilter = (key, value) => {

        let newFilters = { ...filters };
        newFilters[key] = value;
        setFilters(newFilters);

    }

    // useEffect to load up the auth check when the component mounts
    useEffect(() => {
        if (flashState.msg) {
            setError(flashState);
            flashActions.clear();
        }
    }, [setError, flashState, flashActions]);

    const checkUserPermissions = () =>{
        let authInfo = Encryption.extractHeaders(authCookie);
        if(!authInfo.permissions.Users){
            navigate(redirectUrl)
            return
        } else {
            setUserPermissions(authInfo.permissions)
            loadApplications()
        }
    }

    const handleScroll = (e) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) >= (e.target.clientHeight - 20);
        if (bottom) {
            loadApplications();
        }
    }

    // Archive a single application
    const archiveApplication = (aid) => {

        let authInfo = Encryption.extractHeaders(authCookie);

        // Do the fetch request to archive the application
        fetch(process.env.REACT_APP_API_BASE + '/admin-applications/archive/' + aid, {
            method: 'get',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            }
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            if (data.rslt === "success") {
                loadApplications(true);
                setError({ msg: data.msg, style: "green" });
                return;
            }

            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "There was an error archiving this application. Please try again.", style: "red" });
        });

    }

    // Archive based upon the date 
    const bulkArchive = () => {

        if (!window.confirm("Are you sure you want to archive all applications that were submitted before " + moment(archiveDate).format('DD/MM/YYYY') + "?")) {
            return;
        }

        let authInfo = Encryption.extractHeaders(authCookie);

        // Set the data for the archive 
        let archiveData = {
            date: moment(archiveDate).format('YYYY-MM-DD') + " 00:00:00"
        }

        // Do the fetch request to archive the application
        fetch(process.env.REACT_APP_API_BASE + '/admin-applications/bulk-archive', {
            method: 'post',
            headers: {
                "Content-type": "application/json; charset=utf-8",
                'api-token': authInfo.apiToken,
                'api-key': authInfo.apiKey
            },
            body: JSON.stringify(archiveData)
        })
        .then(Fetching.statusCheck)
        .then(Fetching.jsonExtract)
        .then(function (data) {

            // Set the state for the users
            if (data.rslt === "success") {
                loadApplications(true);
                setError({ msg: data.msg, style: "grn" });
                return;
            }

            setError({ msg: data.msg, style: "red" });

        })
        .catch(function (error) {
            setError({ msg: "There was an error archiving this batch of applications. Please try again.", style: "red" });
        });

    }


    /* eslint-disable */
    useEffect(() => {
        document.title = 'Manage users :: Morel Trust';
        checkUserPermissions()
        loadApplications();
    },[])
    /* eslint-enable */

    return (
        <ApplicationList
            error={ error }
            applications={ applications }
            updateFilter={ updateFilter }
            userPermissions={userPermissions}
            handleScroll={ handleScroll }
            loading={loading}
            archiveDate={archiveDate}
            setArchiveDate={setArchiveDate}
            loadApplications={loadApplications}
            archiveApplication={archiveApplication}
            bulkArchive={bulkArchive}
        />
    );
}

export default ApplicationListContainer;
